import React, { useContext } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';

const ModalClosingEntity = () => {
  const { isCloseModalOpen, setIsCloseModalOpen, setIsEntityOpen, setIsEntityChanged, setEntityData } = useContext(
    ArchitectureContext
  );

  const toggleModal = () => {
    setIsCloseModalOpen(!isCloseModalOpen);
  };

  const closeModalHandler = () => {
    setIsEntityChanged(false);
    setIsEntityOpen(false);
    setEntityData(null);
    toggleModal();
  };

  return (
    <Modal isOpen={isCloseModalOpen}>
      <ModalHeader toggle={toggleModal}>Closing without changes</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to close form without saving changes?</p>
      </ModalBody>
      <ModalFooter>
        <Button data-cy="architecture-modal-button-cancel" type="button" className="btn-light" onClick={toggleModal}>
          Cancel
        </Button>
        <Button data-cy="architecture-modal-button-submit" type="submit" color="secondary" onClick={closeModalHandler}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalClosingEntity;
