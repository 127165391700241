import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';

import NoDataMsg from '../../UI/NoDataMsg';
import { ViewContext } from '../../contexts/ViewContext';
import { convertDataForSkeleton } from '../CustomizedSkeletonComponent';
import axios from '../../../axiosInstance';
import ButtonWithConfirmationModal from '../../UI/ButtonWithConfirmationModal';
import { AuthContext } from '../../contexts/AuthContext';
import Paginator from '../../UI/Table/Paginator';
import { DEFAULT_SIZE_PER_PAGE } from '../../../utils/constants';
import debounce from 'lodash/debounce';
import { handleDateConversion } from '../../../utils/timeConversion';
import SearchInput from '../../UI/SearchInput';
import Select from 'react-select';
import Spinner from '../Spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const LastActivitiesTable = ({
  logTitle,
  fetchLogsUrl,
  fetchCategoriesUrl,
  minDate = null,
  isAllowHiding = true,
  isAllowDownloading = true,
  reRender = false,
  setReRender = '',
}) => {
  const controller = new AbortController();
  const screenSize = useContext(ViewContext);
  const { user } = useContext(AuthContext);

  const [activity, setActivity] = useState(null);
  const [search, setSearch] = useState('');
  const [pager, setPager] = useState({ currentPage: 1 });
  const [fetchingData, setFetchingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filter, setFilter] = useState({
    label: 'All',
    value: null,
  });
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadState, setDownloadState] = useState(false);
  const [isTooLarge, setIsTooLarge] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [currentUrl, setCurrentUrl] = useState(fetchLogsUrl);

  const tableName =
    logTitle.replace(/\s+(\w)/g, (_, char) => char.toUpperCase()).replace(/^\w/, char => char.toLowerCase()) + 'Log';

  const tableSizes = JSON.parse(localStorage.getItem('tableSizes'));
  const [sizePerPage, setSizePerPage] = useState(
    tableSizes && tableSizes[tableName] ? tableSizes[tableName] : DEFAULT_SIZE_PER_PAGE
  );

  useEffect(() => {
    if (!tableSizes || !tableSizes[tableName]) {
      localStorage.setItem('tableSizes', JSON.stringify({ ...tableSizes, [tableName]: DEFAULT_SIZE_PER_PAGE }));
    }
  }, []);

  const setSizePerPageHandler = perPage => {
    setSizePerPage(perPage);
    localStorage.setItem('tableSizes', JSON.stringify({ ...tableSizes, [tableName]: perPage }));
  };

  useEffect(() => {
    if (fetchCategoriesUrl) {
      fetchLogCategories();
    }
  }, []);

  useEffect(() => {
    fetchLogs(currentPage, sizePerPage, search, filter.value);
  }, [sizePerPage, currentPage]);

  useEffect(() => {
    if (currentUrl !== fetchLogsUrl) {
      setSearch('');
      setFilter({
        label: 'All',
        value: null,
      });

      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        fetchLogs(1, sizePerPage, search, null);
      }

      if (fetchCategoriesUrl) {
        fetchLogCategories();
      }
      setCurrentUrl(fetchLogsUrl);
    }
  }, [fetchLogsUrl]);

  useEffect(() => {
    if (reRender === true) {
      fetchLogs(currentPage, sizePerPage, search, filter.value);
      setReRender(false);
    }
  }, [reRender]);

  const delayedQuery = useCallback(
    debounce((currentPage, sizePerPage, search, filter) => {
      fetchLogs(currentPage, sizePerPage, search, filter);
    }, 500),

    []
  );

  const toddleDownloadModal = () => {
    setIsDownloadModalOpen(!isDownloadModalOpen);

    if (isDownloadModalOpen) {
      setIsTooLarge(false);
    }
  };

  const columns = [
    {
      dataField: 'createdOn',
      text: 'Time',
      classes: screenSize === 'xl' ? 'lg' : 'md',
    },
    {
      dataField: 'user',
      text: 'User',
      classes: screenSize === 'xl' ? 'xl' : 'lg',
    },
    {
      dataField: 'body',
      text: 'Event',
      classes: screenSize === 'xl' ? 'xxl' : screenSize === 'lg' ? 'xl' : 'lg',
    },
  ];

  const convertedSkeletonColumns = convertDataForSkeleton(columns, 7);

  if (user.isFirstTenant && isAllowHiding) {
    columns.push({
      dataField: '',
      value: 'actions',
      text: '',
      style: {
        width: '7%',
      },
      formatter: (c, row, rowIndex) => (
        <ButtonWithConfirmationModal
          type="button"
          className="bg-transparent px-1 my-auto ml-2"
          onClick={() => {
            handleDeleteLog(row.id);
          }}
          label="Hide log"
          message="Are you sure you want to hide this log?"
          dataCy="record-delete-button"
        >
          <span style={{ color: 'grey', fontSize: '1rem' }}>
            <i className="fas fa-eye-slash " />
          </span>
        </ButtonWithConfirmationModal>
      ),
    });
  }

  const fetchLogs = (currentPage, pageSize, search, filter) => {
    setFetchingData(true);

    const params = {
      page: currentPage,
      perPage: pageSize,
    };

    if (search) {
      params.search = search;
    }

    if (filter) {
      params.filter = filter;
    }

    axios
      .get(fetchLogsUrl, {
        signal: controller.signal,
        params,
      })
      .then(res => {
        if (res?.data?.data) {
          setActivity(handleDateConversion(res.data.data));
          setPager(res.data.meta);
        }
        setFetchingData(false);
      })
      .catch(e => {
        console.warn(e.message);
        setFetchingData(false);
      });
  };

  const fetchLogCategories = () => {
    axios
      .get(fetchCategoriesUrl, {
        signal: controller.signal,
      })
      .then(res => {
        if (res) {
          const filterOptions = [
            {
              label: 'All',
              value: null,
            },
            ...res.data.map(item => {
              let label = item.label;

              // eslint-disable-next-line default-case
              switch (label) {
                case 'Auth Service':
                  label = 'Service Token';
                  break;
                case 'Block Storage':
                  label = 'Persistent Storage';
                  break;
                case 'Dns Zone':
                  label = 'DNS Zone';
                  break;
                case 'KubernetesInstance':
                  label = 'K8s';
                  break;
                case 'VmImport':
                  label = 'Device Import';
                  break;
                case 'Vms':
                  label = 'Device';
                  break;
                case 'Ip':
                  label = 'IP';
                  break;
                case 'Iso':
                  label = 'ISO';
                  break;
                case 'WebApplicationFirewall':
                  label = 'WAF';
                  break;
                case 'WebApplicationFirewallForwardingRule':
                  label = 'WAF Forwarding Rule';
                  break;
              }

              return {
                label,
                value: item.value,
              };
            }),
          ];
          setFilterOptions(filterOptions);
        }
      })
      .catch(e => {
        console.warn(e.message);
        setFetchingData(false);
      });
  };

  const exportDataToFile = () => {
    setDownloadState(true);

    const dateFromWithoutTimeZoneChanges = dateFrom.toDateString();
    const dateToWithoutTimeZoneChanges = dateTo.toDateString();

    const instance = axios.create({
      baseURL: process.env.REACT_APP_WEBSITE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    });

    instance.interceptors.request.use(config => {
      const token = localStorage.getItem('token');
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    });

    instance
      .post(fetchLogsUrl + '/export', {
        dateFrom: dateFromWithoutTimeZoneChanges,
        dateTo: dateToWithoutTimeZoneChanges,
      })
      .then(res => {
        if (res.status === 200) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report-logs' + '.' + 'zip');
          document.body.appendChild(link);
          link.click();
          setIsDownloadModalOpen(false);
        } else if (res.status === 202) {
          setIsTooLarge(true);
        }
        setDownloadState(false);
      })
      .catch(e => {
        console.warn(e.message);
        setDownloadState(false);
      });
  };

  const handleSearch = search => {
    setSearch(search);
    delayedQuery(1, sizePerPage, search, filter.value);
  };

  const handleFilter = filterData => {
    setFilter(filterData);
    delayedQuery(1, sizePerPage, search, filterData.value);
  };

  const handleDeleteLog = logId => {
    axios
      .delete(`api/user/deletelog`, {
        data: {
          logId,
        },
      })
      .then(res => {
        if (res && res.status === 200) {
          fetchLogs(1, sizePerPage, search || null, filter.value || null);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const content = (
    <>
      <BootstrapTable
        keyField="id"
        data={activity ? activity : convertedSkeletonColumns[1]}
        columns={activity ? columns : convertedSkeletonColumns[0]}
        classes="text-left"
      />
      {!fetchingData && activity && activity.length === 0 ? (
        !search ? (
          <NoDataMsg noCard value="actions" />
        ) : (
          <NoDataMsg message={`No actions were found`} />
        )
      ) : null}
      <Paginator
        pager={pager}
        setSizePerPageHandler={setSizePerPageHandler}
        setCurrentPage={setCurrentPage}
        fetchingData={fetchingData}
        sizes={[5, 10, 25, 50, 100]}
      />
    </>
  );

  return (
    <>
      <Card className="card-sm">
        <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
          <h3 className="mb-0">{logTitle}</h3>
          <div className="d-flex flex-row justify-content-end align-items-center">
            <>
              {fetchCategoriesUrl && (
                <div className="min-width-rem-12 mr-3">
                  <Select
                    className="w-100"
                    style={{ height: '43px !important' }}
                    options={filterOptions}
                    value={filter}
                    onChange={e => {
                      handleFilter(e);
                    }}
                  />
                </div>
              )}
            </>
            <SearchInput
              onSearch={handleSearch}
              placeholder="Search by event"
              className="input-group max-width-rem-15 mr-3"
              dataCy="search-input-activities"
            />
            {activity && activity.length > 0 && isAllowDownloading && (
              <Button
                data-cy="download-report-button"
                className="download-btn-no-text"
                onClick={() => setIsDownloadModalOpen(true)}
              >
                {downloadState ? (
                  <Spinner download={true} className="order-2 order-md-0 mr-4" />
                ) : (
                  <i className="fas fa-download mr-4" />
                )}
              </Button>
            )}
          </div>
        </CardBody>
        {content}
      </Card>

      <Modal isOpen={isDownloadModalOpen}>
        <ModalHeader toggle={toddleDownloadModal}>Download logs</ModalHeader>
        <ModalBody>
          <div className="my-4">
            <div className="text-center mb-4">
              <strong>Select the date range of up to 3 months.</strong>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col">
                <Label>
                  <strong>Date from</strong>
                </Label>
                <DatePicker
                  selected={dateFrom}
                  startDate={new Date(user.tenant.created_at)}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  minDate={minDate ? new Date(minDate) : null}
                  maxDate={new Date()}
                  placeholderText="start date"
                  onChange={e => {
                    setDateFrom(e);
                    setIsTooLarge(false);
                  }}
                />
              </div>
              <div className="col">
                <Label>
                  <strong>Date to</strong>
                </Label>
                <DatePicker
                  selected={dateTo}
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  maxDate={new Date()}
                  todayButton="Today"
                  placeholderText="end date"
                  onChange={e => {
                    setDateTo(e);
                    setIsTooLarge(false);
                  }}
                />
              </div>
            </div>
          </div>
          {dateTo - dateFrom > 90000000 * 31 * 3 && (
            <div className="w-100 alert alert-danger mt-4" role="alert">
              Selected period is longer than 3 months.
            </div>
          )}
          {isTooLarge && (
            <div className="w-100 alert alert-warning mt-4" role="alert">
              The log file for selected period is too large for browser download. We will email you the file as soon as
              it will be generated.
            </div>
          )}
        </ModalBody>
        <hr className="mt-0" />
        <div className="d-flex justify-content-end align-items-center px-4 pb-4">
          <Button data-cy="addrespool-cancel" type="button" className="mr-2 btn-light" onClick={toddleDownloadModal}>
            Cancel
          </Button>
          {!isTooLarge ? (
            <Button
              data-cy="addrespool-submit"
              disabled={dateTo - dateFrom > 90000000 * 31 * 3 || !dateFrom || !dateTo}
              type="button"
              className="btn ml-2"
              onClick={() => {
                exportDataToFile();
              }}
            >
              {downloadState ? <Spinner download={true} className="mr-3" /> : 'Export'}
            </Button>
          ) : (
            <Button data-cy="addrespool-submit" type="button" className="btn ml-2" onClick={toddleDownloadModal}>
              Got it
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};
export default LastActivitiesTable;
