import React, { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import clsx from 'clsx';
import Paginator from '../../UI/Table/Paginator';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';

const Table = ({
  architectures,
  columns,
  fetchingData,
  rowEvents,
  setSizePerPageHandler,
  setCurrentPage,
  pager,
  sizes,
}) => {
  const skeletonColumns = columns.map(el => ({
    ...el,
    formatter: () => <Skeleton />,
  }));
  let skeletonData = [];
  let i = 0;
  while (i < 5) {
    const dummyDataObject = { id: uuidv4() };
    columns.map(el => (dummyDataObject[el.dataField] = uuidv4()));
    skeletonData = skeletonData.concat(dummyDataObject);
    i++;
  }

  return architectures ? (
    <Fragment>
      <div>
        <BootstrapTable
          keyField="id"
          options={{ defaultSortName: 'breadcrumbs', defaultSortOrder: 'asc' }}
          data={architectures}
          hover={true}
          columns={columns}
          rowEvents={rowEvents}
          classes="entity-block-table"
          wrapperClasses={clsx('mb text-left entity-block-table-container mx-auto')}
        />
        <Paginator
          pager={pager}
          setSizePerPageHandler={setSizePerPageHandler}
          setCurrentPage={setCurrentPage}
          fetchingData={fetchingData}
          sizes={sizes}
        />
      </div>
    </Fragment>
  ) : (
    <BootstrapTable
      keyField="id"
      data={skeletonData}
      hover={true}
      columns={skeletonColumns}
      classes="entity-block-table"
      wrapperClasses={clsx('mb text-left entity-block-table-container mx-auto')}
    />
  );
};

export default Table;
