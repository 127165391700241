import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Main from './Main';
import Login from './Auth/Login';
import PrivateRoute from './hoc/PrivateRoute';
import GuestRoute from './hoc/GuestRoute';
import OnboardingRoute from './hoc/OnboardingRoute';
import withErrorHandler from './hoc/withErrorHandler';
import axios from '../axiosInstance';
import ViewProvider from './contexts/ViewContext';
import PasswordResetFromEmail from './Auth/PasswordResetFromEmail';
import StartDemo from './Demo/StartDemo';
import PasswordChangeRoute from './PasswordChangeRoute';
import EchoProvider from './contexts/EchoContext';
import NotificationProvider from './contexts/NotificationContext';
import DedicatedForm from './DedicatedForm/DedicatedForm';
import Onboarding from './Onboarding';
import DedicatedContactForm from './DedicatedContactForm';
import Congratulations from './Congratulations';
import NewEmailConfirm from './NewEmailConfirm';
import CheckYourEmail from './CheckYourEmail';
import CustomLogsListPage from './CustomLogs/CustomLogsListPage';
import NetworkDetectorHoc from './hoc/NetworkDetectorHoc';
import AuthProvider from './contexts/AuthContext';
import { HeaderProvider } from './contexts/HeaderContext';
import { AnnouncementsProvider } from './contexts/AnnouncementsContext';
import PushNotificationProvider from './contexts/PushNotificationContext';
import OrganizationProvider from './contexts/OrganizationContext';
import { VideoProvider } from './contexts/VideoContext';
import { DemoOptionsProvider } from './contexts/DemoOptionsContext';
import ThirdPartyAuthSuccess from './Auth/ThirdPartyAuthSuccess';
import { CloudProvider } from './contexts/CloudContext';
import UiProvider from './contexts/UiContext';

const SubApp = () => {
  const [rerenderPage, setRerenderPage] = useState(0);

  return (
    <EchoProvider>
      <UiProvider>
        <AuthProvider>
          <DemoOptionsProvider>
            <OrganizationProvider>
              <HeaderProvider>
                <AnnouncementsProvider>
                  <NotificationProvider>
                    <NetworkDetectorHoc rerenderPage={() => setRerenderPage(prevState => prevState + 1)}>
                      <VideoProvider>
                        <CloudProvider>
                          <Switch>
                            <GuestRoute path="/login" component={Login} />
                            <OnboardingRoute path="/welcome" exact component={Onboarding} />
                            <Route path="/dedicated-server" exact component={DedicatedForm} />
                            <Route path="/thirdparty-authsuccess" exact component={ThirdPartyAuthSuccess} />
                            <Route path="/congratulations" component={Congratulations} />
                            <Route path="/checkYourEmail" component={CheckYourEmail} />
                            <Route path="/dedicated-server/:token" component={DedicatedContactForm} />
                            <Route path="/password/reset/:token" component={PasswordResetFromEmail} />
                            <Route path="/demo" exact component={StartDemo} />
                            <Route path="/demo/:token/customize-experience" component={StartDemo} />
                            <Route path="/demo/:token/verify-account" component={StartDemo} />
                            <Route path="/demo/:token" component={StartDemo} />
                            <Route path="/password-change" component={PasswordChangeRoute} />
                            <Route path="/email/confirm/:token" component={NewEmailConfirm} />
                            <Route path="/custom-logs/list" component={CustomLogsListPage} />
                            <PrivateRoute path="/" component={Main} rerenderPage={rerenderPage} />
                          </Switch>
                        </CloudProvider>
                      </VideoProvider>
                    </NetworkDetectorHoc>
                  </NotificationProvider>
                </AnnouncementsProvider>
              </HeaderProvider>
            </OrganizationProvider>
          </DemoOptionsProvider>
        </AuthProvider>
      </UiProvider>
    </EchoProvider>
  );
};

const SubAppWithErrorHandler = withErrorHandler(SubApp, axios);

const App = () => {
  useEffect(() => {
    if (localStorage['night-mode'] === 'true') {
      document.body.className += ' night-mode';
    }
  }, []);

  return (
    <Router>
      <ViewProvider>
        <PushNotificationProvider>
          <SubAppWithErrorHandler />
        </PushNotificationProvider>
      </ViewProvider>
    </Router>
  );
};

export default App;
