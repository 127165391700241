import React, { useContext } from 'react';
import MemoryIcon from '../../../../../../../images/cloudPilot/memory-small-icon.svg';
import CpuIcon from '../../../../../../../images/cloudPilot/cpu-small-icon.svg';
import DiskIcon from '../../../../../../../images/cloudPilot/disk-small-icon.svg';
import SwapIcon from '../../../../../../../images/cloudPilot/swap-small-icon.svg';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from '../../../../../../contexts/PushNotificationContext';

const Hardware = ({ deviceEntity, changeDeviceEntityHandler }) => {
  const { pushNotification } = useContext(PushNotificationContext);

  const hardwareEntitles = [
    {
      key: 'memory',
      icon: MemoryIcon,
      label: 'Memory, Gb',
    },
    {
      key: 'cpuCoreCount',
      icon: CpuIcon,
      label: 'CPU, Cores',
    },
    {
      key: 'diskSize',
      icon: DiskIcon,
      label: 'Disk, Gb',
    },
    {
      key: 'swapDiskSize',
      icon: SwapIcon,
      label: 'Swap, Gb',
    },
  ];

  const changeValueHandler = (key, value) => {
    if (value > 0) {
      changeDeviceEntityHandler(key, value);
    } else {
      pushNotification({
        data: {
          type: 'danger',
          name: `You cannot set a value less than 1`,
        },
        id: uuidv4(),
      });
    }
  };

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-4">
      <div className="architecture-form-block-header p-3">
        <p className="font-size-lg bold mb-0">Hardware</p>
      </div>
      <div className="p-4">
        {hardwareEntitles.map((entity, index) => (
          <div
            className="architecture-form-shadow rounded d-flex justify-content-between align-items-center mb-2 bg-white height-px-40"
            key={index}
          >
            <div className="d-flex">
              <img className="height-px-20 pl-3 pr-2 width-px-50" src={entity.icon} alt="hardware-icon" />
              {entity.label}
            </div>
            <div className="d-flex width-px-70 justify-content-center align-items-center text-blue-dark">
              <button
                className="btn-nostyle bold pr-2"
                onClick={() => changeValueHandler(entity.key, deviceEntity[entity.key] - 1)}
              >
                <span className="font-size-lg">-</span>
              </button>
              <span className="font-size-md">{deviceEntity[entity.key]}</span>
              <button
                className="btn-nostyle bold pl-2 pr-3"
                onClick={() => changeValueHandler(entity.key, deviceEntity[entity.key] + 1)}
              >
                <span className="font-size-lg">+</span>
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="architecture-form-shadow"></div>
    </div>
  );
};

export default Hardware;
