import React, { useContext, useEffect, useState } from 'react';
import Star from './../../../../../images/cloudPilot/star.svg';
import { ArchitectureContext } from '../../context/ArchitectureContext';

const Total = () => {
  const [totalNodes, setTotalNodes] = useState(0);
  const [typesCount, setTypesCount] = useState({
    wan: 0,
    lan: 0,
    firewall: 0,
    device: 0,
    loadBalancer: 0,
  });
  const { architectureDetails } = useContext(ArchitectureContext);

  useEffect(() => {
    let totalCount = 0;
    const newTypes = {
      wan: 0,
      lan: 0,
      firewall: 0,
      device: 0,
      loadBalancer: 0,
    };

    (architectureDetails?.components || []).forEach(component => {
      if (Object.prototype.hasOwnProperty.call(newTypes, component.type)) {
        newTypes[component.type]++;
        totalCount++;
      }
    });

    setTypesCount(newTypes);
    setTotalNodes(totalCount);
  }, [architectureDetails]);

  return (
    <div className="px-4">
      <div className="d-flex align-items-center mt-4 mb-2">
        <img src={Star} alt="star" />
        <h3 className="mb-0 ml-2">Total nodes:</h3>
        <h2 className="text-blue-dark bold mb-0 ml-2">{totalNodes}</h2>
      </div>
      <div className="d-flex justify-content-between">
        {Object.keys(typesCount).map(type => (
          <div key={type} className="rounded bg-white text-center px-3 py-1 m-1 w-100">
            <div className="bold text-blue-dark font-size-lg">x{typesCount[type]}</div>
            <div className="">
              {type.charAt(0).toUpperCase() + type.slice(1)}
              {typesCount[type] > 1 && 's'}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Total;
