import React, { useEffect, useState } from 'react';
import BackupIcon from '../../../../../../../images/cloudPilot/backup-icon.svg';
import axios from '../../../../../../../axiosInstance';
import { cloudId } from '../../../../constants';
import Select from 'react-select';

const Backups = ({ deviceEntity, changeDeviceEntityHandler }) => {
  const [backupJobs, setBackupJobs] = useState([]);
  const fetchBackupJobs = () => {
    axios
      .get('/api/user/backupJob/list/' + cloudId)
      .then(res => setBackupJobs(res.data))
      .catch(e => console.warn(e.message));
  };

  useEffect(() => {
    fetchBackupJobs();
  }, []);

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-4 p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex align-items-center">
          <img className="width-px-60" src={BackupIcon} alt="backupIcon-icon" />
          <div className="width-px-150 ml-3">
            <Select
              className="w-100"
              options={
                backupJobs &&
                backupJobs.map(backupJob => ({
                  key: backupJob.id,
                  label: backupJob.job_name,
                  value: backupJob.id,
                }))
              }
              onChange={e => {
                changeDeviceEntityHandler('backupJobId', e.value);
              }}
              value={
                backupJobs?.find(backupJob => backupJob.id === deviceEntity.backupJobId)
                  ? {
                      label: backupJobs.find(backupJob => backupJob.id === deviceEntity.backupJobId).job_name,
                      value: deviceEntity.backupJobId,
                    }
                  : null
              }
            />
          </div>
        </div>
        <div className="onoffswitch">
          <input
            type="checkbox"
            className="onoffswitch-checkbox"
            id="enableBackup"
            name="enable-backup"
            checked={deviceEntity.enableBackup}
            onChange={e => changeDeviceEntityHandler('enableBackup', !deviceEntity.enableBackup)}
            data-cy="enable-backup-checkbox"
          />
          <label className="onoffswitch-label mb-0" htmlFor="enableBackup" data-cy="enable-backup-label">
            <span className="onoffswitch-inner" />
            <span className="onoffswitch-switch" />
          </label>
        </div>
      </div>
      <div className="d-flex align-content-center">
        <h2 className="bold">Daily Backups</h2>
        <p className="mb-0 ml-2 text-gray">*additional costs apply</p>
      </div>
      <p className="mb-0">
        Daily Backups allow you to assure the stability and security of your system. In case anything goes wrong –
        you’re safe!
      </p>
    </div>
  );
};

export default Backups;
