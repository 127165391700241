import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../../axiosInstance';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import { handleDateObjectConversion } from '../../../../../utils/timeConversion';

const Logs = () => {
  const [logs, setLogs] = useState({
    networks: [],
    servers: [],
    fwAndLb: [],
  });

  const [expandedLogs, setExpandedLogs] = useState({});

  const { architectureDetails } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const fetchLogs = () => {
    axios
      .get(`/api/user/${tenant}/architectures/${id}/logs`)
      .then(res => {
        const logsCopy = {
          networks: res.data.filter(log => log.properties.type === 'wan' || log.properties.type === 'lan'),
          servers: res.data.filter(log => log.properties.type === 'device'),
          fwAndLb: res.data.filter(log => log.properties.type === 'firewall' || log.properties.type === 'loadBalancer'),
        };
        setLogs(logsCopy);
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  const toggleExpanded = logId => {
    setExpandedLogs(prevState => ({
      ...prevState,
      [logId]: !prevState[logId],
    }));
  };

  useEffect(() => {
    fetchLogs();
  }, [architectureDetails]);

  const renderLogProperties = (log, isExpanded) => {
    const entries = Object.entries(log.properties.data);

    if (isExpanded) {
      return (
        <div className="ml-2">
          {entries.map(([key, value]) => (
            <p key={key} className="mb-0">
              {key}: {value === null ? 'null' : value}
            </p>
          ))}
          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleExpanded(log.id)}>
            {' '}
            (<i className="fas fa-sort-up" /> hide)
          </span>
        </div>
      );
    }

    const [firstKey, firstValue] = entries[0] || [];
    const [secondKey, secondValue] = entries[1] || [];
    return (
      <p className="mb-0">
        {firstKey}: {firstValue === null ? 'null' : firstValue}{' '}
        {secondKey && (
          <span>
            , {secondKey}: {secondValue === null ? 'null' : secondValue}
          </span>
        )}
        {entries.length > 2 && (
          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleExpanded(log.id)}>
            {' '}
            (<i className="fas fa-sort-down" /> show all)
          </span>
        )}
      </p>
    );
  };

  return (
    <>
      {logs.networks.length > 0 && (
        <div className="bg-white px-4 pt-4 pb-2 my-2">
          <p className="text-blue-dark">Networks</p>
          {logs.networks.map(log => (
            <div key={log.id} className="d-flex align-items-center mb-3">
              <div className="logs-blue-circle mr-3">
                {log.type === 'createdLog' && <i className="fas fa-plus" />}
                {log.type === 'updatedLog' && <i className="fas fa-pencil-alt" />}
                {log.type === 'deletedLog' && <i className="fas fa-trash" />}
                {log.type === 'transferredLog' && <i className="fas fa-exchange-alt" />}
              </div>
              <div>
                <p className="mb-0 font-size-sm text-gray">{handleDateObjectConversion(log.createdAt)}</p>
                <p className="mb-0">{log.description}</p>
                {log.type === 'updatedLog' && (
                  <div className="font-size-sm mb-0">{renderLogProperties(log, expandedLogs[log.id])}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {logs.servers.length > 0 && (
        <div className="bg-white px-4 pt-4 pb-2 my-2">
          <p className="text-blue-dark">Servers</p>
          {logs.servers.map(log => (
            <div key={log.id} className="d-flex align-items-center mb-3">
              <div className="logs-blue-circle mr-3">
                {log.type === 'createdLog' && <i className="fas fa-plus" />}
                {log.type === 'updatedLog' && <i className="fas fa-pencil-alt" />}
                {log.type === 'deletedLog' && <i className="fas fa-trash" />}
                {log.type === 'transferredLog' && <i className="fas fa-exchange-alt" />}
              </div>
              <div>
                <p className="mb-0">{log.description}</p>
                {log.type === 'updatedLog' && (
                  <div className="font-size-sm mb-0">{renderLogProperties(log, expandedLogs[log.id])}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {logs.fwAndLb.length > 0 && (
        <div className="bg-white px-4 pt-4 pb-2 my-2">
          <p className="text-blue-dark">Firewalls & Load Balancers</p>
          {logs.fwAndLb.map(log => (
            <div key={log.id} className="d-flex align-items-center mb-3">
              <div className="logs-blue-circle mr-3">
                {log.type === 'createdLog' && <i className="fas fa-plus" />}
                {log.type === 'updatedLog' && <i className="fas fa-pencil-alt" />}
                {log.type === 'deletedLog' && <i className="fas fa-trash" />}
                {log.type === 'transferredLog' && <i className="fas fa-exchange-alt" />}
              </div>
              <div>
                <p className="mb-0">{log.description}</p>
                {log.type === 'updatedLog' && (
                  <div className="font-size-sm mb-0">{renderLogProperties(log, expandedLogs[log.id])}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Logs;
