import React from 'react';
import { Input, Label } from 'reactstrap';

const DnsServers = ({ entity, changeEntityHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">DNS settings</p>
        </div>
      </div>
      <div className="d-flex p-3 justify-content-between">
        <div className="mr-1">
          <Label for="dnsServer1">DNS server 1</Label>
          <Input
            type="text"
            value={entity.dnsServer1}
            onChange={e => changeEntityHandler('dnsServer1', e.target.value)}
          />
        </div>
        <div className="ml-1">
          <Label for="dnsServer1">DNS server 1</Label>
          <Input
            type="text"
            value={entity.dnsServer2}
            onChange={e => changeEntityHandler('dnsServer2', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default DnsServers;
