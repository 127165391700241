import React from 'react';
import { Input } from 'reactstrap';

const EntityKeyInput = ({ type, inputKey, entity, changeEntityHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">
            {type} {inputKey}
          </p>
        </div>
      </div>
      <div className="p-3">
        <Input
          id={`${type.toLowerCase()}-{inputKey`}
          type="text"
          value={entity[inputKey]}
          onChange={e => changeEntityHandler(inputKey, e.target.value)}
        />
      </div>
    </div>
  );
};

export default EntityKeyInput;
