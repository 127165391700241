import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { Input, Label } from 'reactstrap';

const NetworkSettings = ({ entity, changeEntityHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Network settings</p>
        </div>
      </div>
      <div className="d-flex justify-content-between p-3">
        <div>
          <Label for="network" className="bold">
            Network
          </Label>
          <Input
            id="network"
            type="text"
            value={entity.network}
            onChange={e => changeEntityHandler('network', e.target.value)}
          />
        </div>
        <div>
          <Label for="subnetSize" className="bold">
            Subnet Size
          </Label>
          <Select
            id="subnetSize"
            options={_.range(1, 32).map(el => ({
              label: '/' + el,
              value: el,
            }))}
            value={{
              label: '/' + entity.subnetSize,
              value: entity.subnetSize,
            }}
            onChange={e => changeEntityHandler('subnetSize', e.value)}
          />
        </div>
        <div>
          <Label for="gateway" className="bold">
            Gateway
          </Label>
          <Input
            id="gateway"
            type="text"
            value={entity.gateway}
            onChange={e => changeEntityHandler('gateway', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default NetworkSettings;
