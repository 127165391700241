import React, { useContext } from 'react';
import ServerIcon from '../../../../../../images/cloudPilot/server-icon.svg';
import FirewallIcon from '../../../../../../images/firewall.svg';
import { ArchitectureContext } from '../../../context/ArchitectureContext';

const SelectedEntity = () => {
  const { entityData, setIsEntityOpen } = useContext(ArchitectureContext);

  const iconMap = {
    internet: ServerIcon,
    device: ServerIcon,
    firewall: FirewallIcon,
    wan: ServerIcon,
  };

  return (
    <div className="architecture-form-block d-flex bg-white rounded w-100 p-3 align-items-center mb-3">
      <button className="entity-form-close-button" onClick={() => setIsEntityOpen(false)}>
        ✕
      </button>
      <img className="width-px-60 mr-3" src={iconMap[entityData.type]} alt="selected-device" />
      <div>
        <p className="font-size-lg bold mb-1">
          Selected {entityData.type} = {entityData.name}
        </p>
        <div className="d-flex">
          <p className="mb-0 mr-2">Need help?</p>
          <a href="" target="_blank" rel="noreferrer">
            Check this guide
          </a>
        </div>
      </div>
    </div>
  );
};

export default SelectedEntity;
