import React, { useState, useContext, useRef, useEffect } from 'react';
import { Card, CardBody, FormGroup, Button, FormFeedback } from 'reactstrap';

import { AuthContext } from '../contexts/AuthContext';
import Form from '../UI/Form';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import axios from '../../axiosInstance';

const TotpTwoFactorAuth = props => {
  const { twoFactorLogin, twoFactorLoginProcess, totpInfo, setTwoFactor } = useContext(AuthContext);
  const [totp, setTotp] = useState(['', '', '', '', '', '']);
  const [warningText, setWarningText] = useState(null);
  const totpInputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    totpInputRefs[0].current.focus();
  }, []);

  useEffect(() => {
    const fullTotp = totp.join('');
    if (fullTotp.length === totp.length) {
      handleSubmit();
    }
  }, [totp]);

  const sendReserveSms = () => {
    axios
      .post('api/user/code/send-reserve-sms', { requestId: totpInfo.requestId })
      .then(res => {
        if (res && res.status === 200) {
          setTwoFactor(res.data);
          props.history.push('/login/code/sms');
        }
      })
      .catch(e => console.warn(e.message));
  };

  const handleSubmit = () => {
    const fullTotp = totp.join('');
    if (fullTotp.length < totp.length) {
      setWarningText(`Code must contain of ${totp.length} symbols`);
      return;
    }

    twoFactorLogin({ code: fullTotp, type: 'totp' }, props, props.setNotificationConnection);
  };

  const handleKeyDown = (e, index) => {
    const totpCopy = [...totp];

    switch (e.key) {
      case 'Backspace':
        e.preventDefault();
        totpCopy[index] = '';
        setTotp(totpCopy);
        if (index !== 0) {
          totpInputRefs[index - 1].current.focus();
        }
        break;
      case 'ArrowLeft':
        if (index !== 0) {
          totpInputRefs[index - 1].current.focus();
        }
        break;
      case 'ArrowRight':
        if (index !== totp.length - 1) {
          totpInputRefs[index + 1].current.focus();
        }
        break;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        e.preventDefault();
        setWarningText(false);
        totpCopy[index] = e.key;
        setTotp(totpCopy);
        if (index < totpCopy.length - 1 && e.key !== '') {
          totpInputRefs[index + 1].current.focus();
        }
        break;
      default:
        break;
    }
  };

  const handleInput = (e, index) => {
    if (/^\d$/.test(e.target.value)) {
      const totpCopy = [...totp];
      setWarningText(false);
      totpCopy[index] = e.target.value;
      setTotp(totpCopy);
      if (index < totpCopy.length - 1) {
        totpInputRefs[index + 1].current.focus();
      }
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').trim();

    if (pastedData.length === totp.length && !isNaN(pastedData)) {
      const newOtp = pastedData.split('');
      setTotp(newOtp);
      totpInputRefs[pastedData.length - 1].current.focus();
    } else {
      setWarningText('The text on the clipboard is not a code');
    }
  };

  return (
    <Card className="login">
      <CardBody>
        <Form submit={handleSubmit}>
          {totpInfo.secret && (
            <div className="text-center">
              <p>
                Scan the code below with your authentication app (like Google Authenticator or Auth) to login. Then
                enter the code from the authenticator app below to verify and activate TOTP 2FA for this account.
                <br />
                You can also enter the setup key manually:
                <br />
                <code>{totpInfo.secret}</code>
              </p>
              <QRCode
                value={`otpauth://totp/${process.env.REACT_APP_WEBSITE_NAME}:${totpInfo.email}?secret=${totpInfo.secret}`}
              />
            </div>
          )}
          <FormGroup className="mt-4" onPaste={handlePaste}>
            <p className="mb-1">Two-factor authentication</p>
            <p className="font-weight-normal text-muted text-center">Enter the code from your app</p>
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex">
                {totp.map((number, index) => (
                  <input
                    key={index}
                    className={
                      index === 0
                        ? 'totpBorderLeft totpNumber'
                        : index === totp.length - 1
                        ? 'totpBorderRight totpNumber'
                        : 'totpBorderCenter totpNumber'
                    }
                    ref={totpInputRefs[index]}
                    type="text"
                    inputMode="numeric"
                    value={number}
                    placeholder="x"
                    onKeyDown={e => handleKeyDown(e, index)}
                    onChange={e => handleInput(e, index)}
                  />
                ))}
              </div>
              <span className="text-sm text-danger">{warningText ? warningText : <>&nbsp;</>}</span>
            </div>
            <FormFeedback />
          </FormGroup>
          <Button color="primary" disabled={twoFactorLoginProcess} className="w-100 mt-0">
            Verify and Login{' '}
            {twoFactorLoginProcess && (
              <div className="spinner-border spinner-border-sm text-primary ml-2" role="status" />
            )}
          </Button>
          <div className="d-flex grid-gap-8">
            {totpInfo.status === 'totp_with_sms' && (
              <Button className="btn btn-light btn-secondary w-100 mt-2" onClick={sendReserveSms}>
                Send SMS code
              </Button>
            )}
            <Link to="/login/code/totp-lost-device" className="btn btn-light btn-secondary w-100 mt-2">
              Lost the device?
            </Link>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default TotpTwoFactorAuth;
