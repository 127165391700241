import React, { useState } from 'react';
import MonitoringIcon from '../../../../../../../images/cloudPilot/monitoring-icon.svg';

const Monitoring = ({ deviceEntity, changeDeviceEntityHandler, changePasswordHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-4 p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <img className="width-px-60" src={MonitoringIcon} alt="monitoring-icon" />
        <div className="onoffswitch">
          <input
            type="checkbox"
            className="onoffswitch-checkbox"
            id="enableMonitoring"
            name="enable-monitoring"
            checked={deviceEntity.enableMonitoring}
            onChange={e => changeDeviceEntityHandler('enableMonitoring', !deviceEntity.enableMonitoring)}
            data-cy="architecture-enable-monitoring"
          />
          <label
            className="onoffswitch-label mb-0"
            htmlFor="enableMonitoring"
            data-cy="architecture-enable-monitoring-label"
          >
            <span className="onoffswitch-inner" />
            <span className="onoffswitch-switch" />
          </label>
        </div>
      </div>
      <h2 className="bold">Monitoring Service</h2>
      <p className="mb-0">
        Monitoring Service allows you to see extended usage statistics on your device, with an ability to set alarms for
        different scenarios.
      </p>
    </div>
  );
};

export default Monitoring;
