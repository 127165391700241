import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import DemoOptionsContext from '../contexts/DemoOptionsContext';
import { HeaderContext } from '../contexts/HeaderContext';
import { AuthContext } from '../contexts/AuthContext';
import HeaderDropdown from './Dropdown';
import NotificationsDropdown from './NotificationsDropdown';
import ReportDropdown from './ReportDropdown';
import ReportModal from '../Reports/ReportModal';
import { headerIconsClassnames } from '../../utils/constants';
import CreditsLogo from '../../images/credits-logo.svg';
import DropdownLogo from '../../images/chevron-down-solid.svg';
import Documentation from './Documentation';
import AnnouncementItem from '../AdminPanel/Announcements/AnnouncementItem';
import SpotlightDropdown from './SpotlightDropdown';
import axios from '../../axiosInstance';
import clsx from 'clsx';
import { UiContext } from '../contexts/UiContext';

const Header = ({ toggleModal, toggleMenu, history, menuTogglerVisible }) => {
  const [dropdownOpen, setDropdownOpen] = useState({
    account: false,
    notifications: false,
    report: false,
    spotlight: false,
  });
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [reportType, setReportType] = useState(false);
  const { handleCreditsButtonClicked } = useContext(DemoOptionsContext);
  const { user, userPermissions } = useContext(AuthContext);

  const { header } = useContext(HeaderContext);
  const { overlay, setOverlay, finishSearchHandler } = useContext(UiContext);
  const toggleDropdown = (type, isOpen = undefined) => {
    setDropdownOpen({ ...dropdownOpen, [type]: isOpen === undefined ? !dropdownOpen[type] : isOpen });
  };

  const openReportModal = type => {
    setReportType(type);
    setReportModalVisible(true);
  };

  const menuToggle = menuTogglerVisible ? (
    <button className="navbar-toggler navbar-toggler-in-header" onClick={toggleMenu}>
      <i className="fas fa-bars" />
    </button>
  ) : null;

  let headerIcon = { icons: 'fas fa-tachometer-alt' };
  if (header !== 'Dashboard') {
    headerIcon = headerIconsClassnames.find(el => history.location.pathname.indexOf(el.path) >= 0);
  }

  useEffect(() => {
    if (dropdownOpen.spotlight) {
      setOverlay(true);
    }
  }, [dropdownOpen.spotlight]);

  const refreshNotRememberMeToken = () => {
    const token = localStorage.getItem('token');
    const tokenParts = token.split('.');
    const tokenBody = JSON.parse(atob(tokenParts[1]));
    const currentDate = new Date();
    const expDate = new Date(tokenBody.exp * 1000);

    if (expDate - currentDate < 0) {
      axios.get('api/user/stay-alive').catch(e => {
        console.warn(e.message);
      });
    }
  };

  useEffect(() => {
    const timeoutId = setInterval(refreshNotRememberMeToken, 60 * 1000);
    return () => clearInterval(timeoutId);
  }, []);

  return (
    <>
      <header className="main-header">
        <div className="user-panel-container">
          <div className="user-panel-container__block">
            {menuToggle}
            {header && <i className={`uspan-header-icon ${headerIcon && headerIcon.icons}`} />}
            {header && <h1>{header === 'loading' ? <Skeleton width={80} /> : header}</h1>}
          </div>
          {(user.acceptDocumentsDaysLeft === undefined || user.acceptDocumentsDaysLeft >= 0) && (
            <SpotlightDropdown
              isOpen={dropdownOpen.spotlight}
              toggleDropdown={(event, isOpen) => toggleDropdown('spotlight', isOpen)}
            />
          )}
          <div className="user-panel-container__block">
            <NotificationsDropdown
              isOpen={dropdownOpen.notifications}
              toggleDropdown={() => toggleDropdown('notifications')}
            />
            <Documentation />
            {process.env.REACT_APP_WHITELABELING !== 'true' && (
              <ReportDropdown
                isOpen={dropdownOpen.report}
                toggleDropdown={() => toggleDropdown('report')}
                openReportModal={openReportModal}
              />
            )}
            {userPermissions.allowViewCreditsInfo &&
              userPermissions.allowViewOrganizationInvoices &&
              (user.isFirstTenant || user.isXelonChild) && (
                <div
                  data-cy="header-credits-button"
                  className="user-panel-credits-container"
                  onClick={handleCreditsButtonClicked}
                >
                  <img src={CreditsLogo} alt="credits" />
                  <span>{user.credits ? user.credits : 0}</span>
                </div>
              )}
            <div
              data-cy="header-account-button"
              className="user-panel-account-container"
              onClick={() => toggleDropdown('account')}
            >
              <div className="user-panel-name-container">
                <p>{user.full_name ? user.full_name : 'Name Surname'}</p>
              </div>
              <div className="profile-pic-container">
                {user.avatar && <img src={user.avatar} alt="profile-img-plc" />}
              </div>
              <div className="user-panel-dropdown">
                <img className="img-night" src={DropdownLogo} alt="dropdown" />
              </div>
              <HeaderDropdown
                isOpen={dropdownOpen.account}
                toggleDropdown={() => toggleDropdown('account')}
                toggleModal={toggleModal}
              />
            </div>
          </div>
        </div>
        {reportModalVisible && (
          <ReportModal
            modalOpen={reportModalVisible}
            type={reportType}
            toggleModal={() => setReportModalVisible(!reportModalVisible)}
          />
        )}
        <AnnouncementItem />
      </header>
      <div className={clsx(overlay && 'overlay-show')} onClick={finishSearchHandler} />
    </>
  );
};

Header.propTypes = {
  toggle: PropTypes.func,
};

export default withRouter(Header);
