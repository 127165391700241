import React, { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import axios from '../../../../../../../axiosInstance';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';
import { cloudId } from '../../../../constants';

const LanNameType = ({ lanEntity, changeLanEntityHandler }) => {
  const [cloudsForStretching, setCloudsForStretching] = useState([]);

  const AuthCont = useContext(AuthContext);
  const OrganizationCont = useContext(OrganizationContext);
  const tenantId = OrganizationCont.tenantId ? OrganizationCont.tenantId : AuthCont.tenant;

  const fetchHvs = async () => {
    const res = await axios.get(`/api/user/hv/list/${tenantId}`);
    if (res && res.data && res.data.length > 0) {
      setCloudsForStretching(
        res.data
          .filter(cloudItem => cloudItem.id !== cloudId)
          .map(cloudItem => ({
            label: cloudItem.display_name,
            value: cloudItem.id,
          }))
      );
    }
  };

  useEffect(() => {
    fetchHvs();
  }, []);

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">LAN name and type</p>
        </div>
      </div>
      <div className="px-3 pt-3 pb-2">
        <Input type="text" value={lanEntity.name} onChange={e => changeLanEntityHandler('name', e.target.value)} />
        <div className="d-flex mt-2 ml-4">
          <Input
            id="stretched"
            type="checkbox"
            checked={lanEntity.isStretched}
            onChange={() => changeLanEntityHandler('isStretched', !lanEntity.isStretched)}
          />
          <label htmlFor="stretched" className="ml-2">
            stretched
          </label>
        </div>
      </div>
      {lanEntity.isStretched && (
        <div className="px-3 pb-3">
          <Label for="stretchedCloud">Destination stretched cloud</Label>
          <Select
            dataCy="add-network-stretched-cloud-select"
            required
            options={cloudsForStretching}
            value={
              lanEntity.cloudForStretching
                ? {
                    value: lanEntity.cloudForStretching,
                    label: cloudsForStretching.find(cloudItem => cloudItem.value === lanEntity.cloudForStretching)
                      ?.label,
                  }
                : {
                    value: cloudsForStretching[0].value,
                    label: cloudsForStretching[0].label,
                  }
            }
            onChange={e => {
              changeLanEntityHandler('cloudForStretching', e.value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LanNameType;
