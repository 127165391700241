import React, { useContext, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { ArchitectureContext } from '../context/ArchitectureContext';
import clsx from 'clsx';

export const ArchitectureCode = () => {
  const [selected, setSelected] = useState('');
  const [code, setCode] = useState('');
  const { architectureDetails } = useContext(ArchitectureContext);

  useEffect(() => {
    if (architectureDetails) {
      setSelected(architectureDetails.components[0].id);
      setCode(JSON.stringify(architectureDetails.components[0].data, null, 2));
    }
  }, [architectureDetails]);

  const clickFileHandler = component => {
    setSelected(component.id);
    setCode(JSON.stringify(component.data, null, 2));
  };

  return (
    architectureDetails && (
      <div className="container mt-4">
        <div className="mt-4 pt-4">
          <div className="d-flex justify-content-between overflow-auto">
            {architectureDetails.components.map(component => (
              <div
                key={component.id}
                className={clsx(
                  'px-4 py-3 w-100 cursor-pointer',
                  selected === component.id ? 'bg-soft-blue border-bottom border-width-3 border-blue-dark' : 'bg-white'
                )}
                onClick={() => clickFileHandler(component)}
              >
                {component.name}.js
              </div>
            ))}
          </div>
          <div className="architecture-code-editor-wrapper">
            <Editor height="80vh" language="hcl" value={code} />
          </div>
        </div>
      </div>
    )
  );
};

export default ArchitectureCode;
