import React, { useContext, useEffect, useState } from 'react';
import SelectedEntity from './formComponents/SelectedEntity';
import NamingAndOs from './formComponents/Device/NamingAndOs';
import Hardware from './formComponents/Device/Hardware';
import Security from './formComponents/Device/Security';
import Monitoring from './formComponents/Device/Monitoring';
import Backups from './formComponents/Device/Backups';
import { Button } from 'reactstrap';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';

const ServerForm = () => {
  const [deviceEntity, setDeviceEntity] = useState({});
  const [isDeviceEntityReady, setIsDeviceEntityReady] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { entityData, setIsEntityChanged, fetchDetails, setIsEntityOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  useEffect(() => {
    if (entityData) {
      setDeviceEntity({
        cpuCoreCount: entityData?.data?.cpuCoreCount || 2,
        diskSize: entityData?.data?.diskSize || 10,
        name: entityData?.data?.name || 'Server',
        hostname: entityData?.data?.hostname || 'server',
        memory: entityData?.data?.memory || 2,
        password: entityData?.data?.password || '',
        passwordConfirmation: entityData?.data?.passwordConfirmation || '',
        swapDiskSize: entityData?.data?.swapDiskSize || 1,
        templateId: entityData?.data?.templateId || null,
        enableMonitoring: entityData?.data?.enableMonitoring || false,
        enableBackup: entityData?.data?.enableBackup || true,
        backupJobId: entityData?.data?.backupJobId || null,
      });
      setIsDeviceEntityReady(true);
    }
  }, [entityData]);

  const changeDeviceEntityHandler = (key, value) => {
    setDeviceEntity({
      ...deviceEntity,
      [key]: value,
    });
  };

  const changePasswordHandler = newPassword => {
    setDeviceEntity({
      ...deviceEntity,
      password: newPassword,
      passwordConfirmation: newPassword,
    });
  };

  const updateScriptHandler = async () => {
    setIsUpdating(true);

    axios
      .put(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}`, { type: 'device', data: deviceEntity })
      .then(res => {
        setIsUpdating(false);
        setIsEntityChanged(false);
        setIsEntityOpen(false);
        fetchDetails();
      })
      .catch(e => {
        setIsUpdating(false);
        console.warn(e.message);
      });
  };

  return (
    <div className="d-flex flex-column full-height">
      {isDeviceEntityReady ? (
        <div>
          <SelectedEntity />
          <NamingAndOs deviceEntity={deviceEntity} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          <Hardware deviceEntity={deviceEntity} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          <Security
            deviceEntity={deviceEntity}
            changeDeviceEntityHandler={changeDeviceEntityHandler}
            changePasswordHandler={changePasswordHandler}
          />
          <Monitoring deviceEntity={deviceEntity} changeDeviceEntityHandler={changeDeviceEntityHandler} />
          <Backups deviceEntity={deviceEntity} changeDeviceEntityHandler={changeDeviceEntityHandler} />
        </div>
      ) : (
        <span>Loading...</span>
      )}
      <div className="architecture-sticky-button-wrapper mt-3">
        <Button className="btn-violet w-100" onClick={() => updateScriptHandler()} disabled={isUpdating}>
          {isUpdating && (
            <span className="spinner-border spinner-border-sm mb-1 mr-2" role="status" aria-hidden="true" />
          )}
          Update script
        </Button>
      </div>
    </div>
  );
};

export default ServerForm;
