import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import RightArrow from '../../../../../../../images/right-arrow.svg';
import { protocolsMeta } from '../../../../../../../utils/constants';
import Select from '../../../../../../UI/SelectRequired';

const InboundRules = ({
  firewallEntity,
  changeFirewallEntityHandler,
  changeInboundRuleHandler,
  addInboundRuleHandler,
}) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Inbound Rules</p>
        </div>
      </div>
      {firewallEntity?.inboundRules?.length > 0 &&
        firewallEntity.inboundRules.map((rule, index) => (
          <div key={index} className="px-3 pt-3">
            <div className="architecture-form-shadow p-3 position-relative">
              <button
                className="trash-button-right btn btn-sm btn-danger px-2"
                onClick={() => {
                  const newRules = [...firewallEntity.inboundRules];
                  newRules.splice(index, 1);
                  changeFirewallEntityHandler('inboundRules', newRules);
                }}
              >
                <i className="fas fa-trash" />
              </button>
              <div className="d-flex mt-2">
                <div className="w-50">
                  <Label for={`inboundRule-${index}-source`} className="ml-1 bold">
                    Source
                  </Label>
                  <Input
                    id={`inboundRule-${index}-source`}
                    data-cy={`inboundRule-${index}-source`}
                    type="text"
                    className="form-control"
                    value={rule.source}
                    onChange={e => {
                      changeInboundRuleHandler(index, 'source', e.target.value);
                    }}
                    placeholder="Source"
                  />
                </div>
                <img src={RightArrow} alt="right-arrow" className="align-self-center mx-2 mt-4" />
                <div className="w-50">
                  <Label for={`inboundRule-${index}-destination`} className="ml-1 bold">
                    Destination
                  </Label>
                  <Input
                    id={`inboundRule-${index}-destination`}
                    data-cy={`inboundRule-${index}-destination`}
                    type="text"
                    className="form-control"
                    value={rule.destination}
                    onChange={e => {
                      changeInboundRuleHandler(index, 'destination', e.target.value);
                    }}
                    placeholder="Destination"
                  />
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="w-50">
                  <Label
                    for={`inboundRule-${index}-service`}
                    className="ml-1 bold"
                    onClick={() => {
                      console.log(protocolsMeta.find(({ name }) => name.toLowerCase() === rule.service));
                    }}
                  >
                    Service
                  </Label>
                  <Select
                    id={`inboundRule-${index}-service`}
                    data-cy={`inboundRule-${index}-service`}
                    value={
                      protocolsMeta
                        .map(({ name }) => ({
                          value: name.toLowerCase(),
                          label: name,
                        }))
                        .find(option => option.value === rule.service) || null
                    }
                    options={protocolsMeta.map(({ name }) => ({
                      value: name.toLowerCase(),
                      label: name,
                    }))}
                    onChange={e => {
                      changeInboundRuleHandler(index, 'service', e.value);
                    }}
                    placeholder="Select..."
                    portalized
                  />
                </div>
                <div className="w-50 mx-2">
                  <Label for={`inboundRule-${index}-protocol`} className="ml-1 bold">
                    Protocol
                  </Label>
                  <Input
                    id={`inboundRule-${index}-protocol`}
                    data-cy={`inboundRule-${index}-protocol`}
                    type="text"
                    className="form-control"
                    value={rule.protocol}
                    onChange={e => {
                      changeInboundRuleHandler(index, 'protocol', e.target.value);
                    }}
                    placeholder="Protocol"
                  />
                </div>
                <div>
                  <Label for={`inboundRule-${index}-portRange`} className="ml-1 bold">
                    Port Range
                  </Label>
                  <Input
                    id={`inboundRule-${index}-portRange`}
                    data-cy={`inboundRule-${index}-portRange`}
                    type="text"
                    className="form-control"
                    value={rule.portRange}
                    onChange={e => {
                      changeInboundRuleHandler(index, 'portRange', e.target.value);
                    }}
                    placeholder="Port Range"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      <Button className="btn btn-sm btn-success ml-3 my-3" onClick={addInboundRuleHandler}>
        Add Rule
      </Button>
    </div>
  );
};

export default InboundRules;
