import React from 'react';
import Select from 'react-select';

const NetworkSpeed = ({ networkSpeeds, entity, changeEntityHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Network speed</p>
        </div>
      </div>
      <div className="p-3">
        <Select
          options={networkSpeeds}
          value={networkSpeeds.find(obj => obj.value === entity.currentSpeed)}
          onChange={e => changeEntityHandler('networkSpeed', parseInt(e.value, 10))}
        />
      </div>
    </div>
  );
};

export default NetworkSpeed;
