import React, { useContext, useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { handleDateObjectConversion } from '../../../../../utils/timeConversion';

const ModalEntityLogs = () => {
  const [logs, setLogs] = useState([]);
  const [expandedLogs, setExpandedLogs] = useState({});
  const { entityData, isEntityLogsModalOpen, setIsEntityLogsModalOpen } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const toggleModal = () => {
    setIsEntityLogsModalOpen(!isEntityLogsModalOpen);
  };

  const fetchEntityLogs = () => {
    axios
      .get(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}/logs`)
      .then(res => {
        setLogs(res.data);
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  useEffect(() => {
    if (entityData && isEntityLogsModalOpen) {
      fetchEntityLogs();
    }
  }, [entityData, isEntityLogsModalOpen]);

  const toggleExpanded = logId => {
    setExpandedLogs(prevState => ({
      ...prevState,
      [logId]: !prevState[logId],
    }));
  };

  const renderLogProperties = (log, isExpanded) => {
    const entries = Object.entries(log.properties.data);

    if (isExpanded) {
      return (
        <div className="ml-2">
          {entries.map(([key, value]) => (
            <p key={key} className="mb-0">
              {key}: {value === null ? 'null' : value}
            </p>
          ))}
          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleExpanded(log.id)}>
            {' '}
            (<i className="fas fa-sort-up" /> hide)
          </span>
        </div>
      );
    }

    const [firstKey, firstValue] = entries[0] || [];
    const [secondKey, secondValue] = entries[1] || [];
    return (
      <p className="mb-0">
        {firstKey}: {firstValue === null ? 'null' : firstValue}{' '}
        {secondKey && (
          <span>
            , {secondKey}: {secondValue === null ? 'null' : secondValue}
          </span>
        )}
        {entries.length > 2 && (
          <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleExpanded(log.id)}>
            {' '}
            (<i className="fas fa-sort-down" /> show all)
          </span>
        )}
      </p>
    );
  };

  return (
    <Modal isOpen={isEntityLogsModalOpen}>
      <ModalHeader toggle={toggleModal}>{entityData?.name} logs</ModalHeader>
      <ModalBody>
        <div className="node-logs-scroll bg-white px-4 pt-4 pb-2 my-2">
          {logs.map(log => (
            <div key={log.id} className="d-flex align-items-center mb-3">
              <div className="logs-blue-circle mr-3">
                {log.type === 'createdLog' && <i className="fas fa-plus" />}
                {log.type === 'updatedLog' && <i className="fas fa-pencil-alt" />}
                {log.type === 'deletedLog' && <i className="fas fa-trash" />}
                {log.type === 'transferredLog' && <i className="fas fa-exchange-alt" />}
              </div>
              <div>
                <p className="mb-0 font-size-sm text-gray">{handleDateObjectConversion(log.createdAt)}</p>
                <p className="mb-0">{log.description}</p>
                {log.type === 'updatedLog' && (
                  <div className="font-size-sm mb-0">{renderLogProperties(log, expandedLogs[log.id])}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button data-cy="architecture-modal-button-cancel" type="button" className="btn-light" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEntityLogs;
