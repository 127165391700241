import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

import Form from '../../../../utils/form/form';
import axios from '../../../../axiosInstance';
import cloneObject from '../../../../utils/cloneObject';
import clsx from 'clsx';
import { cloudId } from '../../ArchitecturePage/constants';
import { AuthContext } from '../../../contexts/AuthContext';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

const CreateArchitectureModal = ({ modalIsOpen, toggleModal, architectureCreated }) => {
  const [form, setForm] = useState(
    new Form({
      name: '',
      cloudId: null,
    })
  );
  const [availableClouds, setAvailableClouds] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const { tenant } = useContext(AuthContext);

  const fetchHvs = async () => {
    const res = await axios.get(`/api/user/hv/list/${tenant}`);
    if (res && res.data && res.data.length > 0) {
      setAvailableClouds(
        res.data.map(cloudItem => ({
          label: cloudItem.display_name,
          value: cloudItem.id,
        }))
      );
      onChangeHandler('cloudId', res.data[0].id);
    }
  };

  useEffect(() => {
    fetchHvs();
  }, []);

  const onChangeHandler = (key, value) => {
    const clonedForm = cloneObject(form);
    clonedForm[key] = value;
    setForm(clonedForm);
  };

  const createArchitectureHandler = async () => {
    setIsPending(true);

    axios
      .post(`api/user/${tenant}/architectures`, form)
      .then(res => {
        toggleModal(false);
        setIsPending(false);
        architectureCreated(res.data.identifier);
      })
      .catch(() => {
        setIsPending(false);
      });
  };

  return (
    <Modal isOpen={modalIsOpen}>
      <ModalHeader toggle={toggleModal}>Create Architecture</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="name">Architecture name</Label>
          <Input
            data-cy="name-input"
            id="name"
            name="name"
            className={clsx(form.errors.has('name') && 'is-invalid', form.validatedFields.has('name') && 'is-valid')}
            value={form.name}
            onChange={e => onChangeHandler('name', e.target.value)}
            required
          />
          <FormFeedback className="d-block input__danger-message">{form.errors.first('name')}</FormFeedback>
        </FormGroup>
        {availableClouds.length > 0 ? (
          <FormGroup>
            <Label for="stretchedCloud">Destination stretched cloud</Label>
            <Select
              dataCy="add-network-stretched-cloud-select"
              required
              options={availableClouds}
              value={{
                value: form.cloudId,
                label: availableClouds.find(cloudItem => cloudItem.value === form.cloudId)?.label,
              }}
              onChange={e => {
                onChangeHandler('cloudId', e.value);
              }}
            />
          </FormGroup>
        ) : (
          <Skeleton count={1} />
        )}
      </ModalBody>
      <ModalFooter>
        <Button data-cy="architecture-modal-button-cancel" type="button" className="btn-light" onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          data-cy="architecture-modal-button-submit"
          type="submit"
          color="secondary"
          onClick={createArchitectureHandler}
          disabled={isPending}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateArchitectureModal;
