import React, { useContext, useEffect, useState } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import clsx from 'clsx';
import { ArchitectureContext } from '../../context/ArchitectureContext';
import axios from '../../../../../axiosInstance';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';

const ModalTransferEntity = () => {
  const [isHasChildren, setIsHasChildren] = useState(false);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [nodeForTransfer, setNodeForTransfer] = useState('');
  const {
    isTransferModalOpen,
    setIsTransferModalOpen,
    setIsEntityChanged,
    entityData,
    architectureDetails,
    fetchDetails,
    getTemplateAllowedParents,
  } = useContext(ArchitectureContext);
  const { tenant } = useContext(AuthContext);
  const { id } = useParams();

  const toggleModal = () => {
    setIsTransferModalOpen(!isTransferModalOpen);
  };

  useEffect(() => {
    if (architectureDetails?.components && entityData) {
      setIsHasChildren(architectureDetails.componentLinks.some(link => link.fromComponentId === entityData.id));

      const allowedTypes = getTemplateAllowedParents(entityData.type);

      const filtered = architectureDetails.components
        .filter(component => allowedTypes.includes(component.type) && component.id !== entityData.id)
        .map(component => ({
          id: component.id,
          name: component.type + ': ' + component.name,
        }));
      setFilteredComponents(filtered);
    }
  }, [entityData, architectureDetails]);

  const transferModalHandler = () => {
    axios
      .patch(`/api/user/${tenant}/architectures/${id}/node/${entityData.id}/transfer`, {
        parentNodeId: nodeForTransfer,
      })
      .then(res => {
        setIsEntityChanged(false);
        toggleModal();
        fetchDetails();
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  return (
    <Modal isOpen={isTransferModalOpen}>
      <ModalHeader toggle={toggleModal}>
        Transferring {entityData?.type} {entityData?.name}?
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="transferNode">Please select source {entityData?.type} node by name:</Label>
          <Input
            type="select"
            name="transferNode"
            id="transferNode"
            className="form-control"
            onChange={e => {
              setNodeForTransfer(e.target.value);
            }}
            value={entityData?.transferNode}
          >
            <option value="">Select node</option>
            {filteredComponents.map(component => (
              <option key={component.id} value={component.id}>
                {component.name}
              </option>
            ))}
          </Input>
          <FormFeedback>Please select {entityData?.type} by name</FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button data-cy="architecture-modal-button-cancel" type="button" className="btn-light" onClick={toggleModal}>
          Cancel
        </Button>
        <Button data-cy="architecture-modal-button" type="submit" color="danger" onClick={transferModalHandler}>
          Transfer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTransferEntity;
