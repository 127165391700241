import React from 'react';
import { Input } from 'reactstrap';

const WanName = ({ wanEntity, changeWanEntityHandler }) => {
  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Wan name and type</p>
        </div>
      </div>
      <div className="px-3 pt-3 pb-2">
        <Input type="text" value={wanEntity.name} onChange={e => changeWanEntityHandler('name', e.target.value)} />
      </div>
    </div>
  );
};

export default WanName;
