import React, { useContext } from 'react';
import { ArchitectureContext } from '../context/ArchitectureContext';
import ServerForm from './forms/ServerForm';
import FirewallForm from './forms/FirewallForm';
import WanForm from './forms/WanForm';
import LanForm from './forms/LanForm';
import clsx from 'clsx';

const EntityForms = () => {
  const { entityData, isEntityOpen } = useContext(ArchitectureContext);

  let content;
  switch (entityData?.type) {
    case 'device':
      content = <ServerForm />;
      break;
    case 'firewall':
      content = <FirewallForm />;
      break;
    case 'wan':
      content = <WanForm />;
      break;
    case 'lan':
      content = <LanForm />;
      break;
    default:
      content = true;
  }

  return <div className={clsx('entity-form', isEntityOpen && 'entity-form-open')}>{content}</div>;
};

export default EntityForms;
