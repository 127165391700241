import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import EntityHeader from '../../UI/EntityPage/EntityHeader';
import ArchitecturesSvg from '../../../images/organizations.svg';
import { HeaderContext } from '../../contexts/HeaderContext';
import * as globalVars from '../../helpers/variables';
import ListEntityName from '../../UI/ListEntityName';
import Table from './Table';
import { createdAtColumn, DEFAULT_SIZE_PER_PAGE } from '../../../utils/constants';
import { useCtrlListener } from '../../hooks/useBtnListener';
import { Link, useHistory } from 'react-router-dom';
import SearchInput from '../../UI/SearchInput';
import debounce from 'lodash/debounce';
import axios from '../../../axiosInstance';
import { Badge, Button } from 'reactstrap';
import CreateArchitectureModal from './Modals/CreateArchitectureModal';
import { AuthContext } from '../../contexts/AuthContext';
import moment from 'moment/moment';
import ButtonWithConfirmationModal from '../../UI/ButtonWithConfirmationModal';

const ArchitecturesList = ({ match }) => {
  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    setHeader('Infrastracture Environments');
  }, [setHeader]);

  const [architectures, setArchitectures] = useState(null);

  const tableSizes = JSON.parse(localStorage.getItem('tableSizes'));
  const [sizePerPage, setSizePerPage] = useState(
    tableSizes && tableSizes.architectures ? tableSizes.architectures : DEFAULT_SIZE_PER_PAGE
  );
  const [fetchingData, setFetchingData] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [pager, setPager] = useState({ currentPage: 1 });
  const [currentPage, setCurrentPage] = useState(1);
  const ctrlPressed = useCtrlListener();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    fetchData(currentPage, sizePerPage, search);
  }, [sizePerPage, currentPage]);

  const setSizePerPageHandler = perPage => {
    setSizePerPage(perPage);
  };

  const { tenant } = useContext(AuthContext);

  const fetchData = (page, perPage, search) => {
    setArchitectures(null);
    setFetchingData(true);

    const payload = {
      page,
      perPage,
    };

    if (search) {
      payload.search = search;
    }

    axios
      .get(`api/user/${tenant}/architectures`, payload)
      .then(res => {
        if (res?.data) {
          setArchitectures(res.data.data);
        }
        setFetchingData(false);
      })
      .catch(e => {
        console.warn(e.message);
        setFetchingData(false);
      });
  };

  const delayedQuery = useCallback(
    debounce((currentPage, sizePerPage, search) => {
      fetchData(currentPage, sizePerPage, search);
    }, 500),
    []
  );
  const handleSearch = search => {
    setSearch(search);
    delayedQuery(1, sizePerPage, search);
  };

  const redirectToArchitecture = identifier => {
    const urlToOpen = `${match.url}/architecture/${identifier}`;
    history.push(urlToOpen);
  };

  const columns = [
    {
      dataField: 'cloudName',
      text: '',
      style: { width: '100px' },
      formatter: (c, row) => {
        const name = c ? c.charAt(0).toUpperCase() + c.slice(1) : '';
        return (
          <Fragment>
            {row?.hv_system?.id === 1 || row?.hv_system?.id === 6 ? (
              <Badge className={`hv-type-badge hv-type-badge-${row.hv_system.id}`}>{name}</Badge>
            ) : (
              <Badge className={`hv-type-badge hv-type-badge-${c}`}>{name}</Badge>
            )}
          </Fragment>
        );
      },
    },
    {
      dataField: 'name',
      text: 'Name',
      align: 'left',
      headerAlign: 'left',
      sort: false,
      formatter: c => <ListEntityName entityName={c} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      align: 'left',
      headerAlign: 'left',
      sort: false,
      formatter: c => <ListEntityName entityName={c} />,
    },
    {
      dataField: 'createdAt',
      text: 'Creation Date',
      headerStyle: {
        minWidth: '100px',
        paddingRight: 0,
        paddingLeft: 0,
      },
      style: {
        minWidth: '100px',
        paddingRight: 0,
        paddingLeft: 0,
      },
      align: 'left',
      headerAlign: 'left',
      sort: true,
      formatter: cell => moment(cell).format('DD MMM YYYY'),
    },
    {
      dataField: 'updatedAt',
      text: 'Last modified',
      headerStyle: {
        minWidth: '100px',
        paddingRight: 0,
        paddingLeft: 0,
      },
      style: {
        minWidth: '100px',
        paddingRight: 0,
        paddingLeft: 0,
      },
      align: 'left',
      headerAlign: 'left',
      sort: true,
      formatter: cell => moment(cell).format('DD MMM YYYY'),
    },
    {
      dataField: 'identifier',
      text: '',
      align: 'right',
      classes: 'pl-sm',
      formatter: (c, row) => (
        <ButtonWithConfirmationModal
          id="deleteCheck"
          message="You're about to delete this Architecture. Do you want to proceed?"
          label="Delete Architecture"
          color="pink"
          size="sm"
          onClick={() => deleteServiceHandler(row.identifier)}
          dataCy={`delete-architecture-${row.id}`}
          modalCancelText="No, cancel"
          modalConfirmText="Yes, delete"
        >
          Delete
        </ButtonWithConfirmationModal>
      ),
    },
  ];

  const rowEvents = {
    onClick: (e, row) => {
      if (e.target.id !== 'deleteCheck') {
        const urlToOpen = `${match.url}/architecture/${row.identifier}`;
        if (!ctrlPressed) {
          history.push(urlToOpen);
        } else if (ctrlPressed) {
          window.open(urlToOpen, '_blank');
        }
      }
    },
  };

  const deleteServiceHandler = identifier => {
    axios
      .delete(`api/user/${tenant}/architectures/${identifier}`)
      .then(() => {
        fetchData(currentPage, sizePerPage, search);
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  return (
    <>
      <div className="col-12">
        <EntityHeader
          entitySvg={ArchitecturesSvg}
          entityName="Architectures"
          customMessage="View and manage your architectures."
        />
        <div className={'entity-block-table-header mx-auto mt'}>
          <SearchInput
            onSearch={handleSearch}
            placeholder="Search Architecture"
            className="input-group"
            dataCy="search-input-architecture"
          />
          <Button
            data-cy="create-new-network"
            className="btn btn-primary text-white"
            onClick={() => setCreateModalOpen(true)}
          >
            <i className="fas fa-plus mr-2" />
            New Architecture
          </Button>
        </div>

        <Table
          architectures={architectures}
          columns={columns}
          fetchingData={fetchingData}
          rowEvents={rowEvents}
          setSizePerPageHandler={setSizePerPageHandler}
          setCurrentPage={setCurrentPage}
          pager={pager}
          sizes={[5, 10, 20]}
        />
        <CreateArchitectureModal
          modalIsOpen={createModalOpen}
          toggleModal={() => setCreateModalOpen(!createModalOpen)}
          architectureCreated={e => redirectToArchitecture(e)}
        />
      </div>
    </>
  );
};

export default ArchitecturesList;
