import React, { useState, createContext, useEffect } from 'react';

export const HeaderContext = createContext({
  header: undefined,
  setHeader: () => null,
});

export const HeaderProvider = props => {
  const [header, setHeader] = useState(null);
  const value = { header, setHeader };

  useEffect(() => {
    if (process.env.REACT_APP_WEBSITE_NAME && header !== null && header !== 'loading') {
      document.title = `${process.env.REACT_APP_WEBSITE_NAME} | ${header}`;
    }
  }, [header]);

  return <HeaderContext.Provider value={value}>{props.children}</HeaderContext.Provider>;
};
