import React, { useContext, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import axios from '../../../../../../../axiosInstance';
import { cloudId } from '../../../../constants';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import { OrganizationContext } from '../../../../../../contexts/OrganizationContext';

const SubnetSize = ({ entity, changeEntityHandler }) => {
  const [optionsStretched, setOptionsStretched] = useState([]);
  const [optionsDedicated, setOptionsDedicated] = useState([]);

  const AuthCont = useContext(AuthContext);
  const OrganizationCont = useContext(OrganizationContext);
  const tenantId = OrganizationCont.tenantId ? OrganizationCont.tenantId : AuthCont.tenant;

  const fetchSubnets = async () => {
    axios
      .post('api/user/subnets-sizes', { cloudId, tenantIdentifier: tenantId })
      .then(res => {
        if (res.data) {
          if (Object.keys(res.data.subnets.dedicated).length > 0) {
            const dedicated = [];
            Object.keys(res.data.subnets.dedicated).forEach(el => {
              dedicated.push({
                value: `/${el}`,
                label: res.data.subnets.dedicated[el],
              });
            });
            setOptionsDedicated(dedicated);
          }
          if (Object.keys(res.data.subnets.stretched).length > 0) {
            const stretched = [];
            Object.keys(res.data.subnets.stretched).forEach(el => {
              stretched.push({
                value: `/${el}`,
                label: res.data.subnets.stretched[el],
              });
            });
            setOptionsStretched(stretched);
          }
        }
      })
      .catch(e => console.warn(e.message));
  };

  useEffect(() => {
    fetchSubnets();
  }, []);

  return (
    <div className="architecture-form-block w-100 rounded bg-white mt-2">
      <div className="bg-light-blue">
        <div className="architecture-form-block-header p-3">
          <p className="font-size-lg bold mb-0">Subnet Size</p>
        </div>
      </div>
      <div className="p-3">
        <Select
          options={entity.isStretched ? optionsStretched : optionsDedicated}
          value={
            entity.isStretched
              ? optionsStretched.find(subnet => subnet.value === '/' + entity.subnetSize)
              : optionsDedicated.find(subnet => subnet.value === '/' + entity.subnetSize)
          }
          onChange={e => changeEntityHandler('subnetSize', parseInt(e.value.replace('/', ''), 10))}
        />
      </div>
    </div>
  );
};

export default SubnetSize;
