import axios from 'axios';
import { updateToken, removeToken } from './utils/auth';

const config = {
  baseURL: process.env.REACT_APP_WEBSITE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  flags: {},
};

const instance = axios.create(config);

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(
  res => {
    if (typeof res.data === 'object' && res.data['0'] !== undefined) {
      const data = [];
      Object.keys(res.data).forEach(key => (data[parseInt(key)] = res.data[key]));
      res.data = data;
    }
    return res;
  },
  error => {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      if (errorResponse.config.url === `${process.env.REACT_APP_WEBSITE_URL}/api/user/refresh`) {
        removeToken();
        return (window.location.href = '/login');
      }
      return resetTokenAndReattemptRequest(error);
    } else if (isTokenInvalidError(errorResponse)) {
      const releaseId = localStorage.getItem('releaseId');
      localStorage.clear();
      localStorage.setItem('releaseId', releaseId);
      if (window.location.pathname !== '/login') {
        return (window.location.href = '/login');
      }
      // } else if (isNotFoundError(errorResponse)) {
      //   if (window.location.pathname !== '/not-found') {
      //     return (window.location.href = '/not-found');
      //   }
    } else if (isServerError500(errorResponse) && instance.defaults.flags.redirectTo500) {
      if (window.location.pathname !== '/server-error-500') {
        return (window.location.href = '/server-error-500');
      }
    }
    // else if (isServerError500(errorResponse)) {
    //   if (window.location.pathname !== '/server-error-500') {
    //     return (window.location.href = '/server-error-500');
    //   }
    // }
    // If the error is due to other reasons, we just throw it back to axios
    // usually, we cancel requests on purpose via controller.abort(), so there is no need to show a message on the UI
    if (error.code !== 'ERR_CANCELED') {
      return Promise.reject(error);
    }
    // return error;
  }
);

function isTokenExpiredError(errorResponse) {
  // determine if the error is due to JWT token expired returns a boolean value
  const status = errorResponse ? errorResponse.status : null;
  return status === 410;
}

function isTokenInvalidError(errorResponse) {
  const status = errorResponse ? errorResponse.status : null;
  return status === 401;
}

function isNotFoundError(errorResponse) {
  const status = errorResponse ? errorResponse.status : null;
  return status === 404;
}

function isServerError500(errorResponse) {
  const status = errorResponse ? errorResponse.status : null;
  return status === 500;
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const resetToken = localStorage.getItem('token'); // getting the token to refresh the JWT token
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
            We create a new Promise that will retry the request,
            clone all the request configuration from the failed
            request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
      /* We need to add the request retry to the queue
                since there another request that already attempted to
                refresh the token */
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(instance(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      // REFRESH TOKEN
      instance({
        method: 'post',
        url: `${process.env.REACT_APP_WEBSITE_URL}/api/user/refresh`,
        data: {
          token: resetToken,
        },
      })
        .then(response => {
          if (response) {
            if (!response.data) {
              return Promise.reject(error);
            }
            const newToken = response.data.token;
            saveToken(newToken); // save the newly refreshed token for other requests to use
            isAlreadyFetchingAccessToken = false;
            setTimeout(() => onAccessTokenFetched(newToken), 1000);
          }
        })
        .catch(e => {
          removeToken();
          return (window.location.href = '/login');
        });

      return retryOriginalRequest;
    }
    return retryOriginalRequest;
  } catch (err) {
    removeToken();
    return (window.location.href = '/login');
  }
}

instance.refreshCallbacks = [];

instance.addRefreshCallback = callback => {
  instance.refreshCallbacks.push(callback);
};

function onAccessTokenFetched(access_token) {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function saveToken(token) {
  updateToken(token);
  instance.refreshCallbacks.forEach(callback => callback(token));
}

export default instance;
