import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthConsumer } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, key, match, rerenderPage, ...rest }) => {
  Object.values(rest.computedMatch.params).forEach(field => {
    key += field;
  });

  return (
    <AuthConsumer>
      {({ loggedInAsOtherUser, isAuth, passwordShouldBeChanged, onboardingCompleted, menu }) => (
        <Route
          {...rest}
          render={props => {
            if (isAuth) {
              if (!loggedInAsOtherUser && passwordShouldBeChanged) {
                return <Redirect to={{ pathname: '/password-change' }} />;
              } else if (!onboardingCompleted) {
                return <Redirect to={{ pathname: '/welcome' }} />;
              } else if (match) {
                const regex = match.regex;
                if (!regex.test(props.match.params[match.valTOMatch])) {
                  return <Redirect to={{ pathname: '/not-found' }} />;
                }
                return <Component key={key} {...props} menu={menu} />;
              } else if (rerenderPage >= 0) {
                return <Component key={key} {...props} menu={menu} rerenderPage={rerenderPage} />;
              } else {
                return <Component key={key} {...props} menu={menu} />;
              }
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              );
            }
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default PrivateRoute;
