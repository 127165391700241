import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import markerSDK from '@marker.io/browser';
import { AuthContext } from '../contexts/AuthContext';

const ReportDropdown = ({ toggleDropdown, isOpen, openReportModal }) => {
  const { user } = useContext(AuthContext);
  const reportBugHandler = () => {
    if (
      process.env.REACT_APP_MARKER_IO_PROJECT_ID &&
      process.env.REACT_APP_WHITELABELING !== 'true' &&
      user.featureFlags.includes('bug-report')
    ) {
      markerSDK
        .loadWidget({
          project: process.env.REACT_APP_MARKER_IO_PROJECT_ID,
          source: 'snippet',
          extension: false,
          silent: true,
        })
        .then(res => {
          res.setReporter({
            email: user.email,
            fullName: user.fullName,
          });
          return res;
        })
        .then(res => {
          res.capture('advanced');
        })
        .catch(error => {
          console.error('Error loading Marker.io widget:', error);
          openReportModal('Bug');
        });
    } else {
      openReportModal('Bug');
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="d-inline-block">
      <DropdownToggle data-cy="header-bug-button" className="nav-link user-panel-square" color="default">
        <i className="fas fa-bug mr-3" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem data-cy="header-bug-report-button" onClick={reportBugHandler}>
          <i className="fa fa-bug mr-2" style={{ fontSize: '13px' }} />
          Report bug
        </DropdownItem>
        <DropdownItem data-cy="header-feature-report-button" onClick={() => openReportModal('Improvement')}>
          <i className="fa fa-drafting-compass mr-2" style={{ fontSize: '13px' }} />
          Request a feature
        </DropdownItem>
        {process.env.REACT_APP_IS_TECHNICAL_SUPPORT_REQUEST_ENABLED === 'true' && (
          <DropdownItem data-cy="header-feature-report-button" tag="a" href="https://support.xelon.ch/" target="_blank">
            <i className="fa fa-users-cog mr-2" style={{ fontSize: '13px' }} />
            Request Technical Support
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

ReportDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  openReportModal: PropTypes.func.isRequired,
};

export default ReportDropdown;
