import * as React from 'react';
const CostSvg = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill={color}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M.508 7.007a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm6.5-5.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.321 5.253c-.231 0-.467.21-.467.52 0 .235.05.32.076.352.03.039.09.086.25.143l2.009.7c.235.085.503.216.704.473.205.262.288.59.288.966 0 .818-.633 1.52-1.467 1.52H6.461c-.91 0-1.613-.765-1.613-1.667a.5.5 0 0 1 1 0c0 .392.297.667.613.667h1.253c.234 0 .467-.205.467-.52 0-.23-.05-.317-.077-.35-.03-.04-.092-.09-.25-.145L5.848 7.21c-.232-.082-.502-.211-.703-.468-.207-.263-.29-.591-.29-.97 0-.81.632-1.52 1.467-1.52h1.253c.91 0 1.614.765 1.614 1.667a.5.5 0 0 1-1 0c0-.392-.297-.667-.614-.667H6.321Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M7.016 3.587a.5.5 0 0 1 .5.5v6.006a.5.5 0 1 1-1 0V4.087a.5.5 0 0 1 .5-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CostSvg;
