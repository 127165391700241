import React, { createContext, useRef, useState } from 'react';
import { withRouter } from 'react-router';

const UiContext = createContext({});

const UiProvider = ({ children, location }) => {
  const [overlay, setOverlay] = useState(false);

  const finishSearchHandler = () => {
    setOverlay(false);
  };

  const changeDarkModeHandler = darkMode => {
    localStorage.setItem('night-mode', darkMode);

    if (darkMode && !document.body.className.includes(' night-mode')) {
      document.body.className += ' night-mode';
    }
    if (!darkMode && document.body.className.includes(' night-mode')) {
      document.body.className = document.body.className.replace(' night-mode', '');
    }
  };

  return (
    <UiContext.Provider
      value={{
        overlay,
        setOverlay,
        finishSearchHandler,
        changeDarkModeHandler,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default withRouter(UiProvider);
export { UiContext };
