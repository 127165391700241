import React, { useState, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { Popover, Button, PopoverBody } from 'reactstrap';

import InternetSvg from '../../../../images/internet.svg';
import ServerSvg from '../../../../images/server-icon.svg';
import FirewallSvg from '../../../../images/firewall.svg';
import LoadBalancerSvg from '../../../../images/load_balancer.svg';
import WanSvg from '../../../../images/cloudPilot/wan-icon.svg';
import LanSvg from '../../../../images/cloudPilot/lan-icon.svg';
import AddComponents from './AddComponents';
import { ArchitectureContext } from '../context/ArchitectureContext';
import clsx from 'clsx';
import CloudSelector from './CloudSelector';

const iconMap = {
  internet: InternetSvg,
  device: ServerSvg,
  firewall: FirewallSvg,
  loadBalancer: LoadBalancerSvg,
  wan: WanSvg,
  lan: LanSvg,
};

export const GraphNode = ({ data, isConnectable }) => {
  const [isHovered, setIsHovered] = useState(false);

  const {
    setIsEntityOpen,
    setIsSummaryOpen,
    setEntityData,
    setIsDeleteModalOpen,
    setIsTransferModalOpen,
    nodeButtonGroupId,
    setNodeButtonGroupId,
    nodeAddModalId,
    setNodeAddModalId,
    isEntityLogsModalOpen,
    setIsEntityLogsModalOpen,
  } = useContext(ArchitectureContext);

  const nodeClickHandler = () => {
    setIsSummaryOpen(false);
    setIsEntityOpen(true);
    setEntityData(data);
  };

  const deleteClickHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId('');
    setNodeAddModalId('');
    setIsDeleteModalOpen(true);
  };

  const transferClickHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId('');
    setNodeAddModalId('');
    setIsTransferModalOpen(true);
  };

  const openEntityLogsHandler = () => {
    setIsEntityOpen(false);
    setEntityData(data);
    setNodeButtonGroupId('');
    setNodeAddModalId('');
    setIsTransferModalOpen(false);
    setIsEntityLogsModalOpen(true);
  };

  return (
    <div
      className="graph-node position-relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {data.type !== 'internet' && (nodeButtonGroupId === data.id || isHovered) && (
        <>
          <div className="d-flex justify-content-end py-1 position-absolute w-100">
            <Button
              id={'button-group' + data.id}
              color="btn btn-sm btn-white node-button-group"
              onClick={() => {
                setNodeAddModalId('');
                setNodeButtonGroupId(data.id);
              }}
            >
              <i className="fas fa-ellipsis-v" />
            </Button>
          </div>
          <Popover
            innerClassName="p-2"
            placement="right"
            flip
            target={'button-group' + data.id}
            isOpen={nodeButtonGroupId === data.id}
            positionFixed={false}
            fade={false}
          >
            <PopoverBody>
              <div className="d-flex flex-column align-items-start p-0">
                <Button color="d-flex pl-0 py-0 ml-1" onClick={transferClickHandler}>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-exchange-alt mr-2" />
                    <p className="font-size-sm mb-0">Transfer</p>
                  </div>
                </Button>
                <Button color="d-flex pl-0 py-0 ml-1" onClick={openEntityLogsHandler}>
                  <div className="d-flex align-items-center">
                    <i className="far fa-list-alt mr-2" />
                    <p className="font-size-sm mb-0">Logs</p>
                  </div>
                </Button>
                <Button color="d-flex pl-0 py-0 ml-1 text-danger" onClick={deleteClickHandler}>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-trash mr-2" />
                    <p className="font-size-sm mb-0">Delete</p>
                  </div>
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        </>
      )}
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
      <div className="graph-image-node bg-white" onClick={nodeClickHandler}>
        <img
          className={clsx('mb-2', ['wan', 'lan'].includes(data.type) ? 'width-px-40' : 'width-px-50')}
          src={iconMap[data.type]}
          alt="node-icon"
        />
        <div className="graph-node-label text-center bold">{data.name}</div>
      </div>
      {data.type === 'internet' && <CloudSelector />}
      <span className="graph-add-component">
        <i
          id={'add-' + data.id}
          onClick={() => {
            setNodeButtonGroupId('');
            setNodeAddModalId(data.id);
          }}
          className="fas fa-plus"
        />
        <Popover placement="bottom" flip target={'add-' + data.id} isOpen={nodeAddModalId === data.id} fade={false}>
          <PopoverBody>
            <AddComponents component={data} closePopover={() => setNodeAddModalId('')} />
          </PopoverBody>
        </Popover>
      </span>

      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </div>
  );
};

export default GraphNode;
