import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import VmsIcon from '../../images/server-icon.svg';
import TenantIcon from '../../images/organizations.svg';
import KubIcon from '../../images/kubernetes.svg';
import NetworkIcon from '../../images/networks.svg';
import FirewallIcon from '../../images/firewall.svg';
import LBIcon from '../../images/load_balancer.svg';
import WAFIcon from '../../images/waf_small.svg';
import UserIcon from '../../images/technical-support-user.svg';

import axios from '../../axiosInstance';
import SearchInput from '../UI/SearchInput';
import debounce from 'lodash/debounce';
import clsx from 'clsx';
import Spinner from '../UI/Spinner';
import { Link, useHistory } from 'react-router-dom';
import {
  useCtrlKListener,
  useArrowUpListener,
  useArrowDownListener,
  useEnterListener,
  useEscapeListener,
} from '../hooks/useBtnListener';
import { AuthContext } from '../contexts/AuthContext';
import { UiContext } from '../contexts/UiContext';

const entities = {
  device: {
    title: 'Device',
    icon: VmsIcon,
    permission: 'allowViewVirtualMachine',
  },
  network: {
    title: 'Network',
    icon: NetworkIcon,
    permission: 'allowViewNetwork',
  },
  tenant: {
    title: 'Tenant',
    icon: TenantIcon,
    permission: 'allowViewOrganizations',
  },
  firewall: {
    title: 'Firewall',
    icon: FirewallIcon,
    permission: 'allowViewFirewall',
  },
  load_balancer: {
    title: 'Load balancer',
    icon: LBIcon,
    permission: 'allowViewLoadBalancer',
  },
  waf: {
    title: 'WAF',
    icon: WAFIcon,
    permission: 'allowViewWebApplicationFirewall',
  },
  dns: {
    title: 'DNS',
    icon: NetworkIcon,
    permission: 'allowViewNetworking',
  },
  k8s: {
    title: 'Kubernetes',
    icon: KubIcon,
    permission: 'allowAccessBetaFeatures',
  },
  user: {
    title: 'User',
    icon: UserIcon,
    permission: 'allowManageUsers',
  },
};

const SpotlightDropdown = ({ toggleDropdown, isOpen }) => {
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('all');
  const [search, setSearch] = useState('');
  const ctrlKPressed = useCtrlKListener();
  const upPressed = useArrowUpListener();
  const downPressed = useArrowDownListener();
  const enterPressed = useEnterListener();
  const escapePressed = useEscapeListener();
  const [currentFocusLinkIndex, setCurrentFocusLinkIndex] = useState(null);
  const history = useHistory();
  const { user, userPermissions } = useContext(AuthContext);
  const { finishSearchHandler, overlay, setOverlay } = useContext(UiContext);
  const controllerRef = useRef(new AbortController());

  const splitRegex = new RegExp(search, 'gi');

  useEffect(() => {
    setIsLoading(true);
    delayedQuery(search, selectedType);
  }, [search, selectedType]);

  useEffect(() => {
    if (downPressed && (!currentFocusLinkIndex || currentFocusLinkIndex < results.length - 1)) {
      setCurrentFocusLinkIndex(currentFocusLinkIndex !== null ? currentFocusLinkIndex + 1 : 0);
    }
  }, [downPressed]);

  useEffect(() => {
    if (upPressed && currentFocusLinkIndex && currentFocusLinkIndex !== 0) {
      setCurrentFocusLinkIndex(currentFocusLinkIndex - 1);
    }
  }, [upPressed]);

  useEffect(() => {
    if (enterPressed && currentFocusLinkIndex !== null) {
      const item = results[currentFocusLinkIndex];

      if (item) {
        history.push(getLinkUrl(item.type, item.entity_id, item.tenant_id, item.name));
        setSearch('');
        setResults([]);
        setCurrentFocusLinkIndex(null);
      }
    }
  }, [enterPressed]);

  useEffect(() => {
    if (escapePressed) {
      setResults([]);
      setCurrentFocusLinkIndex(null);
      toggleDropdown('spotlight', false);
      finishSearchHandler();
    }
  }, [escapePressed]);

  useEffect(() => {
    if (overlay === false && search !== '') {
      setSearch('');
      setResults([]);
      setCurrentFocusLinkIndex(null);

      controllerRef.current.abort();
      controllerRef.current = new AbortController();
    }
  }, [overlay]);

  useEffect(() => {
    if (currentFocusLinkIndex !== null) {
      const elem = document.getElementById(`navigationLink${currentFocusLinkIndex}`);

      if (elem) {
        elem.focus();
      }
    }
  }, [currentFocusLinkIndex]);

  const fetchSearch = (search, selectedType) => {
    if (search === '') {
      setIsLoading(true);
      setResults([]);
    } else {
      setIsLoading(true);
      setCurrentFocusLinkIndex(null);
      axios
        .get('/api/user/search-entity', { signal: controllerRef.current.signal, params: { search, selectedType } })
        .then(res => {
          if (res) {
            toggleDropdown('spotlight', true);
            if (res.data) {
              setResults(res.data);
            }
            setIsLoading(false);
          }
        });
    }
  };

  const delayedQuery = useCallback(
    debounce((search, selectedType) => {
      fetchSearch(search, selectedType);
    }, 1000),
    []
  );

  const getLinkUrl = (type, entityId, tenantId, name) => {
    switch (type) {
      case 'user':
        return user.identifier === entityId
          ? '/user-account'
          : `/all-organizations/organization/${tenantId}/user/${entityId}`;
      case 'device':
        return `/all-devices/${entityId}/${tenantId}`;
      case 'network':
        return `/networking/${tenantId}/${entityId}`;
      case 'tenant':
        return `/all-organizations/organization/${entityId}`;
      case 'firewall':
        return `/networking/firewall/${tenantId}/${entityId}`;
      case 'load_balancer':
        return `/networking/load-balancer/${tenantId}/${entityId}`;
      case 'waf':
        return `/networking/web-application-firewall/${tenantId}/${entityId}`;
      case 'dns':
        return `/networking/dns/${tenantId}/${name}`;
      case 'k8s':
        return `/kubernetes-ci/${tenantId}/${entityId}`;
      default:
        return 'dashboard';
    }
  };

  return Object.values(entities).some(entity => userPermissions[entity.permission] === true) ? (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="d-inline-block w-100">
      <DropdownToggle data-cy="header-notification-button" color="default w-100">
        <SearchInput
          className="input-spotlight w-100"
          placeholder={`Search (${navigator.userAgent.indexOf('Mac') !== -1 ? '⌘Cmd' : 'Ctrl'} + k)`}
          onSearch={setSearch}
          value={search}
          dataCy="search-input-spotlight"
          isFocus={ctrlKPressed}
        />
      </DropdownToggle>
      {isOpen && (
        <div className="quick-search-main-box">
          <div className="quick-search">
            <div className="quick-search-triangle" />
            <div className="quick-search-categories">
              <div className="d-flex py-1 flex-wrap">
                <div
                  className={clsx(
                    'mr-4 py-0 my-1 cursor-pointer',
                    selectedType === 'all' ? 'quick-search-type-underline' : 'quick-search-type'
                  )}
                  onClick={() => setSelectedType('all')}
                >
                  All
                </div>
                {Object.entries(entities).map(
                  ([key, value]) =>
                    value.permission &&
                    userPermissions[value.permission] && (
                      <div
                        key={key}
                        className={clsx(
                          'mr-4 py-0 mt-1 cursor-pointer',
                          selectedType === key ? 'quick-search-type-underline' : 'quick-search-type'
                        )}
                        onClick={() => setSelectedType(key)}
                      >
                        {value.title}
                      </div>
                    )
                )}
              </div>
              <hr className="my-0" />
            </div>
            {search === '' && (
              <div className="d-flex flex-row justify-content-center font-gray py-3 quick-search-explain">
                Enter the instance name and select a category if needed.
              </div>
            )}
          </div>
          <DropdownMenu
            className={clsx(
              'quick-search-modal-small-box',
              results.length === 0 && !search && 'd-none',
              isLoading && 'text-center',
              isLoading && 'w-32',
              'quick-search-result'
            )}
          >
            {!isLoading && results.length === 0 && !!search && (
              <div className="d-flex flex-row justify-content-center font-gray py-2">No matches</div>
            )}
            {isLoading ? (
              <Spinner download={true} className="my-2" />
            ) : (
              results.map((item, index) => (
                <div
                  key={item.entity_id + item.type}
                  className={clsx(
                    'notification-dropdown-item-container',
                    'd-flex',
                    'justify-content-between',
                    'link-search',
                    currentFocusLinkIndex === index && 'link-focus'
                  )}
                >
                  <div className="link-search-item">
                    <img src={entities[item.type].icon} className="user-story-icon" alt={`${item.type}-icon`} />
                    <Link
                      className="text-dark ml-1"
                      to={getLinkUrl(item.type, item.entity_id, item.tenant_id, item.name)}
                      id={`navigationLink${index}`}
                      onClick={() => {
                        setResults([]);
                        setCurrentFocusLinkIndex(null);
                        toggleDropdown();
                        finishSearchHandler();
                      }}
                    >
                      {search.length && !!item.name && item.name.split(splitRegex)?.length > 0
                        ? item.name.split(splitRegex).map((word, index) => {
                            return index > 0 ? (
                              <span key={index}>
                                <span className="color-primary bold">{item.name.match(splitRegex)?.[0] || ''}</span>
                                {word}
                              </span>
                            ) : (
                              word
                            );
                          })
                        : item.name}
                    </Link>
                  </div>
                  <p className="font-gray ml-2">{entities[item.type].title}</p>
                </div>
              ))
            )}
          </DropdownMenu>
        </div>
      )}
    </Dropdown>
  ) : null;
};

export default SpotlightDropdown;
