import * as React from 'react';
const LogsSvg = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill={color}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M1.665 9.667V11a2.666 2.666 0 0 0 2.667 2.667h6.667A2.666 2.666 0 0 0 13.665 11V3A2.666 2.666 0 0 0 11 .333H4.332A2.667 2.667 0 0 0 1.665 3v1.333a.667.667 0 0 0 1.334 0V3c0-.736.597-1.333 1.333-1.333h6.667c.736 0 1.333.597 1.333 1.333v8c0 .737-.597 1.333-1.333 1.333H4.332A1.333 1.333 0 0 1 2.999 11V9.667a.667.667 0 0 0-1.334 0Zm5.058-3.334-.862-.861a.668.668 0 0 1 .942-.943l2 2c.261.26.261.682 0 .943l-2 2a.667.667 0 0 1-.942-.943l.862-.862H.999a.667.667 0 0 1 0-1.334h5.724Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LogsSvg;
