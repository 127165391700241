import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../axiosInstance';
import { AuthContext } from '../../../contexts/AuthContext';
import cloneObject from '../../../../utils/cloneObject';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import { ArchitectureContext } from '../context/ArchitectureContext';
import { useParams } from 'react-router-dom';

const CloudSelector = () => {
  const [availableClouds, setAvailableClouds] = useState([]);
  const [selectedCloud, setSelectedCloud] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tenant } = useContext(AuthContext);
  const { architectureDetails, fetchDetails } = useContext(ArchitectureContext);
  const { id } = useParams();

  const fetchHvs = async () => {
    const res = await axios.get(`/api/user/hv/list/${tenant}`);
    if (res && res.data && res.data.length > 0) {
      setAvailableClouds(
        res.data.map(cloudItem => ({
          label: cloudItem.display_name,
          value: cloudItem.id,
        }))
      );
      setSelectedCloud(architectureDetails.cloudId);
    }
  };

  useEffect(() => {
    fetchHvs();
  }, []);

  const onChangeHandler = value => {
    if (value !== selectedCloud.value) {
      setSelectedCloud(value);
      setIsModalOpen(true);
    }
  };

  const changeCloudHandler = () => {
    axios
      .patch(`api/user/${tenant}/architectures/${id}/change-cloud`, { cloudId: selectedCloud })
      .then(res => {
        fetchDetails();
        setIsModalOpen(false);
      })
      .catch(e => {
        console.warn(e.message);
      });
  };

  const cancelCloudHandler = () => {
    setSelectedCloud(architectureDetails.cloudId);
    setIsModalOpen(false);
  };

  return (
    availableClouds.length > 0 && (
      <>
        <div className="node-cloud-selector">
          <span className="bold ml-2 mb-2">Cloud location</span>
          <select data-cy="node-cloud-select" value={selectedCloud} onChange={e => onChangeHandler(e.target.value)}>
            {availableClouds.map(cloud => (
              <option key={cloud.value} value={cloud.value}>
                {cloud.label}
              </option>
            ))}
          </select>
        </div>
        {isModalOpen && (
          <Modal isOpen={isModalOpen}>
            <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Cloud changes</ModalHeader>
            <ModalBody>
              <p>Are you sure you want to change the cloud for this environment?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                data-cy="cancel-change-cloud-for-architecture"
                type="button"
                className="btn-light"
                onClick={cancelCloudHandler}
              >
                Cancel
              </Button>
              <Button
                data-cy="change-cloud-for-architecture"
                type="submit"
                color="primary"
                onClick={changeCloudHandler}
              >
                Change
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    )
  );
};

export default CloudSelector;
